import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import axios from 'axios';
import { 
  Modal,
  Select,
  MenuItem,
  FormControl,
  InputBase,
  withStyles,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';

const BootstrapInput = withStyles((theme) => ({    
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: "white",
    border: 'none',
    outline: "none",
    fontSize: 16,
    padding: "1rem", 
    color: "#908d8c",
    '&:focus': {
      backgroundColor: "white",
      borderRadius: 0,                    
    },
  }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  option: {
    color: "#464444",
    padding: "1rem",
    fontSize: "1.1rem",
    transition: theme.transitions.create(['color']),
    '&:nth-child(odd)': {
      backgroundColor: "#f1eded",
    },
    '&:hover': {
      color: "#e96c11",               
    },
  }
}));



const ContactForm = () => {
  const classes = useStyles();
  const [subject, setSubject] = useState('');
  const [open, setOpen] = useState(false);  
  
  // toast
  const [snackbarOpen, setSnackbarOpen] = useState(false);        
  const [snackbarType, setSnackbarType] = useState('info');    

  const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
          return;
      }        
      setSnackbarOpen(false);
  };

  const formSchema = yup.object().shape({
    Name: yup.string().required("Preencha o campo"),
    Email: yup.string().email("Digite um endereço de email válido").required("Preencha o campo"),
    Phone: yup.string(),
    Message: yup.string().required("Preencha o campo"),
  });

  const { control, handleSubmit, errors } = useForm({
    resolver: yupResolver(formSchema)
  });

  const handleChange = (event) => {
    setSubject(event.target.value);
  };
  
  const handleClose = () => {
    setOpen(false);
  };          
  
  const onSubmit = (values) => {        
    let objSubmit = {
      Name: values.Name,
      Email: values.Email,
      Phone: values.Phone,        
      Message: values.Message
    }
    objSubmit.Subject = subject;                             
    axios
      .post('contact', objSubmit)
      .then(res => {                
        //console.log(res.data);
        setSnackbarType('success');
        setSnackbarOpen(true);
      })
      .catch(err => {
        //console.log(err);
        setSnackbarType('error');
        setSnackbarOpen(true);
      });
  };  

  const SnackBar = () => {        
    const Alert = (props) => (
      <MuiAlert elevation={6} variant="filled" {...props} />
    )
    
    let text;
    switch(snackbarType) {
      case 'success':
        text = "Sua mensagem foi enviada! Entraremos em contato o mais rápido possível.";
        break;
      case 'error':
        text = "Opa, algo deu errado! :(";
        break;
      case 'info':
        text = "Estamos processando, por favor aguarde.";
        break;            
      default:
    }

    return (
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarType}>
          {text}
        </Alert>
      </Snackbar>            
    );
  }   

  return (    
    <div className="wrapper-left">
      <div className="def__label__box">
        <div className="rectangle"></div>
        <p className="label medium">FALE CONOSCO</p>
      </div>
      <h1 className="title">Estamos sempre prontos para atendê-lo</h1>
      <h4 className="description">A equipe Ezata está sempre disposta a lhe auxiliar da melhor forma. Se necessita de mais informações sobre qualquer questão de nossa empresa, deixe sua mensagem e entraremos em contato.</h4>
      <form className="wrapper-form" onSubmit={handleSubmit(onSubmit)}>
        <section className="wrapper-box-1">
          <div className="box__input">
            <Controller
              as={<input aria-label="input" />}
              className="form-name"
              placeholder="Nome"
              name="Name"
              control={control}
              defaultValue=""
            />
            {errors.Name && <label>{errors.Name.message}</label>}
          </div>
          <div className="box__input">
            <Controller
              as={<input aria-label="input" />}
              className="form-email"
              placeholder="Email"
              name="Email"
              control={control}
              defaultValues=""
            />
            {errors.Email && <label>{errors.Email.message}</label>}
          </div>
          <div className="box__input">
            <Controller
              as={
                <InputMask mask="(99) 99999-9999" maskChar=" " />
              }
              className="form-phone"
              placeholder="Telefone"
              name="Phone"
              control={control}
              defaultValues=""
            />
            {errors.Phone && <label>{errors.Phone.message}</label>}
          </div>
        </section>
        <Controller
          as={    
            <FormControl>
              <Select
                onChange={handleChange}
                input={<BootstrapInput />}
                variant="outlined"
                value={subject}                    
                displayEmpty
              >
                <MenuItem value={""} disabled className={classes.option}>Assunto</MenuItem>
                <MenuItem value={"Comercial"} className={classes.option}>Comercial</MenuItem>
                <MenuItem value={"Financeiro"} className={classes.option}>Financeiro</MenuItem>
                <MenuItem value={"Geral"} className={classes.option}>Geral</MenuItem>
                <MenuItem value={"RH"} className={classes.option}>Recursos Humanos</MenuItem>
              </Select> 
            </FormControl>
            }   
            
            className="form-subject"
            placeholder="Assunto"
            name="Subject"
            control={control}
          />
          <div className="box__input">
              <Controller
                  as={<textarea aria-label="input" />}
                  className="form-message"
                  placeholder="Mensagem"
                  name="Message"
                  control={control}
              />
              {errors.Message && <label>{errors.Message.message}</label>}
          </div>
          <button type="submit">ENVIAR</button>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div id="contato-modal">
          <h2>Sua mensagem foi enviada!</h2>
          <p>Logo nossa equipe entrará em contato com você.</p>
        </div>
      </Modal>
      <SnackBar />        
    </div>
  );
};

export default ContactForm;